import { graphql } from "gatsby"
import React,{useState, useEffect} from "react"
import Layout from "../components/layout"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import SEO from "../components/seo"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import PlainContentComponent from "../components/PlainContentComponent/PlainContentComponent"
import qs from "qs"
import { removeDataAttributes } from "../components/Common/utils"

const StaticPage = ({ data }) => {
  // const PageData = data?.strapiPage
  const [PageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  const query = qs.stringify(
    {
      populate: {
        choose_menu: "*",
        select_popular_search:"*",
        add_page_modules: {
          populate:{
            title_desc:{
              populate:"*"
            }
          }
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
      setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        let myPrevData;
        if(PageData){
          myPrevData = PageData
        } else {
          myPrevData = {};
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }
        if(previewData){
          myPrevData.title = previewData?.title
          myPrevData.add_page_modules=previewData?.add_page_modules
          myPrevData.layout = previewData?.layout
          myPrevData.select_popular_search = previewData?.select_popular_search
          setPageData(PageData => ({ ...PageData, myPrevData }));
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
    }

  },[])


  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      
      <BreadcrumbModule
        choose_menu={PageData.choose_menu}
        pagename={PageData.title}
      />
        {PageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
          {(item?.__typename ===
            "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" || 
            item?.__component === "components.title-desc"
          )&& (
            <ContentComponent isPreviewEnabled={isPreviewEnabled} data={item} layout={PageData.layout} />
          )}
           {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT" || 
            item?.__component === "page-modules.plain-content")
            && (
            <PlainContentComponent isPreviewEnabled={isPreviewEnabled} data={item} />
          )}
          </div>
          )
        }
        )}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)

   //preview seo dynamic updates

   useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
            if(parsedData?.metaTitle){
              setPreMetaTitle(parsedData?.metaTitle)
            }
          }
        } catch (error) {
          console.error("Error parsing session storage data:", error);
        }
      }
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
    document.title =  `${preMetaTitle} haus & haus`;
    }
}, [preMetaTitle]);
  //


  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"

      />
  )
}

export default StaticPage

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
        }
      }
    }
  }
`
